import React, {Fragment} from 'react';
import './DiagnosisChallengeComponent.css';
import {useLocation} from "react-router-dom";
import {ModeratorChallengeComponent} from "./ModeratorChallengeComponent";
import {GuesserChallengeComponent} from "./GuesserChallengeComponent";
import {SinglePlayerDiagnosisChallengeComponent} from "./SinglePlayerDiagnosisChallengeComponent";
import UserType from "../common/data/model/UserType";

export const DiagnosisChallengeComponent: React.FC = () => {
    const diagnosisChallenge = useLocation().state.diagnosisChallenge
    return (
        <Fragment>
            {
                diagnosisChallenge.isMultiPlayer ? <MultiPlayerDiagnosisChallengeComponent/>
                    : <SinglePlayerDiagnosisChallenge/>
            }
        </Fragment>
    );
};

const MultiPlayerDiagnosisChallengeComponent: React.FC = () => {
    const state = useLocation().state
    const userType = state.userType
    const diagnosisChallenge = state.diagnosisChallenge
    return (
        <Fragment>
            {userType === UserType.Guesser ?
                <GuesserChallengeComponent story={diagnosisChallenge.story}/> :
                <ModeratorChallengeComponent
                    challenge={diagnosisChallenge}
                    medicalBranches={state.medicalBranches}
                    difficulty={state.difficulty}
                />}
        </Fragment>
    )
}

const SinglePlayerDiagnosisChallenge: React.FC = () => {
    const state = useLocation().state
    const diagnosisChallenge = state.diagnosisChallenge
    return (<SinglePlayerDiagnosisChallengeComponent
        challenge={diagnosisChallenge}
        medicalBranches={state.medicalBranches}
        difficulty={state.difficulty}
    />)
}
import React from 'react';
import { NavLink } from 'react-router-dom'
import './NavLinks.css';

const NavLinks: React.FC = () => {
    return (
        <ul className="nav-links">
            <li>
                <NavLink to="/">Diagnosis Challenge</NavLink>
            </li>
            <li>
                <NavLink to="/about">About</NavLink>
            </li>
        </ul>
    );
}

export default NavLinks;
import React from "react";
import './LoadingComponent.css';
import {DotLoader} from "react-spinners";

export const LoadingComponent: React.FC = () => {
    return (
        <div className="loading-component">
            <h2>Creating new Diagnosis Challenge, please wait.</h2>
            <DotLoader
                size={60}
                color={"#FFFFFF"}
            />
        </div>
    )
}
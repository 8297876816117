import React from 'react';
import './JoinDiagnosisChallengeComponent.css';
import CenterButton from "../../../common/ui/button/CenterButton";
import {atom} from "jotai/index";
import {Input} from "../../../common/ui/input/Input";
import {getDiagnosisChallenge} from "../common/data/DiagnosisChallengeApi";
import {useAtomValue} from "jotai";
import UserType from "../common/data/model/UserType";
import {useNavigate} from "react-router-dom";
import {DiagnosisChallengeGuesserResponse} from "../common/data/model/response/DiagnosisChallengeGuesserResponse";

const lobbyIdAtom = atom("")

export const JoinDiagnosisChallengeComponent: React.FC = () => {
    const lobbyId = useAtomValue(lobbyIdAtom)
    const navigate = useNavigate()
    const handleJoinClick = async () => {
        const response = await getDiagnosisChallenge(lobbyId)
        navigateToDiagnosisChallenge(response)
    }
    const navigateToDiagnosisChallenge = (diagnosisChallenge: DiagnosisChallengeGuesserResponse) => {
        navigate(`/diagnosis-challenge/${diagnosisChallenge.id}`, {
            state: {
                diagnosisChallenge: diagnosisChallenge,
                userType: UserType.Guesser
            }
        })
    }

    return (
        <div className="root-card">
            <h2>Enter the Diagnosis Challenge lobby ID</h2>
            <div className="center">
                <Input
                    style={"input-join"}
                    atom={lobbyIdAtom}
                    placeholder={"Enter the lobby id given from the moderator."}/>
            </div>
            <CenterButton
                text={"Join"}
                onClick={handleJoinClick}
            />
        </div>
    );
};
import {api} from "../../../../common/data/api-client"
import {DiagnosisChallengeModeratorResponse} from "./model/response/DiagnosisChallengeModeratorResponse";
import {CreateDiagnosisChallengeRequest} from "./model/request/CreateDiagnosisChallengeRequest";
import {AskDiagnosisChallengeRequest} from "./model/request/AskDiagnosisChallengeRequest";
import {AnswerDiagnosisChallengeRequest} from "./model/request/AnswerDiagnosisChallengeRequest";
import {DiagnosisChallengeGuesserResponse} from "./model/response/DiagnosisChallengeGuesserResponse";

export const createDiagnosisChallenge = async (
    medicalBranches: Array<string>,
    difficulty: string,
    isMultiPlayer: boolean,
    exclude?: Array<string>
): Promise<DiagnosisChallengeModeratorResponse> => {
    const request = new CreateDiagnosisChallengeRequest(
        medicalBranches,
        difficulty,
        isMultiPlayer,
        exclude
    );

    try {
        const response = await api.post("/diagnosis-challenge", request);
        const data = response.data;
        return new DiagnosisChallengeModeratorResponse(
            data._id,
            data.illness,
            data.symptoms,
            data.diagnosisSteps,
            data.story,
            data.treatment,
            data.isMultiPlayer,
        );
    } catch (error) {
        throw error;
    }
}

export const askDiagnosisChallenge = async (
    story: string,
    question: string,
    illness: string
): Promise<string> => {
    const request = new AskDiagnosisChallengeRequest(
        story,
        question,
        illness
    )
    try {
        const response = await api.post("/diagnosis-challenge/ask", request);
        return response.data.answer
    } catch (error) {
        throw error;
    }
}

export const answerDiagnosisChallenge = async (
    attemptedAnswer: string,
    answer: string,
): Promise<boolean> => {
    const request = new AnswerDiagnosisChallengeRequest(
        attemptedAnswer,
        answer
    )
    try {
        const response = await api.post("/diagnosis-challenge/answer", request);
        return response.data.isCorrect
    } catch (error) {
        throw error;
    }
}

export const getDiagnosisChallenge = async (id: string): Promise<DiagnosisChallengeGuesserResponse> => {
    try {
        const response = await api.get(`/diagnosis-challenge/${id}`)
        const data = response.data;
        return new DiagnosisChallengeModeratorResponse(
            data._id,
            data.illness,
            data.symptoms,
            data.diagnosisSteps,
            data.story,
            data.treatment,
            data.isMultiPlayer,
        );
    } catch (error) {
        throw error
    }
}
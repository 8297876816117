import React from 'react';
import './AboutRootComponent.css';

export const AboutRootComponent: React.FC = () => {
    return (
        <div className="root-card">
            <h3 className="about-header">What is MediMystery?</h3>
            <div className="center">
                <p className="about-text">MediMystery utilizes generative AI to generate patients based on your selected
                    conditions.
                    The generative AI is trained on data from the web, so always consult your doctor and medical
                    literature for anything medicine related. <br/>
                    <br/>
                    MediMystery should just be used as a fun challenge / brain teaser or a refresher / training.</p>
            </div>
            <h3 className="about-header">Why are there ads on the website?</h3>
            <p className="about-text">In order to keep the challenges free, we need to somehow cover our costs. The
                easiest solution is to have
                ads on specific locations.</p>
            <h3 className="about-header">Funding, feedback and future potential upgrades</h3>
            <p className="about-text">As we grow and receive funding, we will be able to use newer, more advances
                generative AI models, which fill lead to us having a bigger, more diverse set of cases as the models
                will be trained on newer, larger datasets
                <br/>
                For any feedback you might have, potential funding or partnerships, please contact us at the email
                below.
            </p>
            <h3 className="about-header">Contact</h3>
            <p className="about-text">repeatableapp@gmail.com</p>
        </div>
    );
};
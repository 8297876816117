import React from 'react';
import './RulesOfGameCard.css';

interface RulesOfGameCardProps {
    content: React.ReactNode;
}
const RulesOfGameCard: React.FC<RulesOfGameCardProps> = ({content}) => {
    return (
        <div className="rules-game-card">
            {content}
        </div>
    );
}

export default RulesOfGameCard;
import React from "react";
import "./GamesLeftComponent.css"
import {Badge} from "@mui/material";

interface GamesLeftProps {
    gamesLeft: number | null
}

export const GamesLeftComponent: React.FC<GamesLeftProps> = ({gamesLeft}) => {
    return (
        <Badge badgeContent={gamesLeft} color="primary">
            <img
                className="coins-img"
                src={"coins.svg"}
                alt={"Free games remaining for today"}/>
        </Badge>
    )
}
export class AskDiagnosisChallengeRequest {

    story: string;
    question: string;
    illness: string;

    constructor(story: string,
                question: string,
                illness: string
    ) {
        this.story = story
        this.question = question
        this.illness = illness
    }
}
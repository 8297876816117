import {atom} from "jotai/index";
import {Toast} from "../../../../common/ui/toast/Toast";
import {ChatMessage} from "../../../../common/presentation/ChatMessage";
import {getRemoteConfigValue} from "../../../../common/RemoteConfigHook";
import {DIAGNOSIS_CHALLENGE_SINGLE_PLAYER_FREE_QUESTIONS} from "../../../../common/data/RemoteConfigConstants";

export const singlePlayerAnswerAtom = atom("")
export const singlePlayerToastAtom = atom<Toast | null>(null);
export const singlePlayerIsAnswerCorrectAtom = atom<boolean | null>(null)
export const singlePlayerQuestionAtom = atom("");
export const singlePlayerChatMessagesAtom = atom<ChatMessage[]>([]);
export const singlePlayerIsCreatingNewChallengeAtom = atom<boolean>(false)
export const singlePlayerQuestionsLeftAtom = atom<number>(Number(getRemoteConfigValue(DIAGNOSIS_CHALLENGE_SINGLE_PLAYER_FREE_QUESTIONS)))

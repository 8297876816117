import React from 'react';
import {useAtom} from 'jotai';
import ToastComponent from './ToastComponent';
import './ToastContainer.css';
import {PrimitiveAtom} from "jotai/index";
import {Toast} from "./Toast";

interface ToastContainerProps {
    atom: PrimitiveAtom<Toast | null>
}

const ToastContainer: React.FC<ToastContainerProps> = ({atom}) => {
    const [toast, setToast] = useAtom(atom);
    const dismissToast = () => {
        setToast(null);
    };
    return (
        <div>
            {
                toast && <div className="toast-container">
                    <ToastComponent
                        toast={toast}
                        onDismiss={dismissToast}
                    />
                </div>
            }
        </div>
    );
};

export default ToastContainer;

import React from 'react';
import {atom, useAtom, useAtomValue, useSetAtom} from 'jotai';
import {useNavigate} from 'react-router-dom';
import Card from "../../../../common/ui/card/Card";
import './DiagnosisRootComponent.css';
import {
    difficultiesAtom,
    gameTypesAtom,
    medicalBranchesAtom,
    selectedGameTypeAtom,
    shouldShowDifficultyAtom,
    shouldShowGameTypesAtom
} from "../DiagnosisStateStore";
import {splitAtom} from "jotai/utils";
import {SelectedGameType} from "../SelectedGameType";
import GameTypeRulesComponent from "./GameTypeRulesComponent";
import CenterButton from "../../../../common/ui/button/CenterButton";
import {createDiagnosisChallenge} from "../../common/data/DiagnosisChallengeApi";
import {MedicalBranch} from "../../../../common/domain/model/MedicalBranch";
import {
    DiagnosisChallengeModeratorResponse
} from "../../common/data/model/response/DiagnosisChallengeModeratorResponse";
import {Toast} from "../../../../common/ui/toast/Toast";
import ToastContainer from "../../../../common/ui/toast/ToastContainer";
import {Difficulty} from "../../../../common/domain/model/Difficulty";
import {GameType} from "../../../../common/domain/model/GameType";
import {
    multiPlayerQuestionsLeftAtom,
    multiPlayerToast
} from "../../common/store/MultiPlayerDiagnosisChallengeAtomStore";
import {
    singlePlayerAnswerAtom,
    singlePlayerChatMessagesAtom,
    singlePlayerIsAnswerCorrectAtom,
    singlePlayerQuestionAtom,
    singlePlayerQuestionsLeftAtom,
    singlePlayerToastAtom
} from "../../common/store/SinglePlayerDiagnosisChallengeAtomStore";
import UserType from "../../common/data/model/UserType";
import {gamesLeftAtom} from "../../../../common/RemoteStorageStore";
import ExoClickResponsiveAd from "../../../../common/ui/ad/ExoClickResponsiveAd";
import ExoClickAd from "../../../../common/ui/ad/ExoClickAd";
import ExoClickObservable from "../../../../common/ui/ad/ExoClickObservable";

const toastAtom = atom<Toast | null>(null);
const noCoinsInterstitialShownAtom = atom<boolean>(false)

function DiagnosisRootComponent() {
    const [gamesLeft, setGamesLeft] = useAtom(gamesLeftAtom)
    const setMultiPlayerToast = useSetAtom(multiPlayerToast)
    const setMultiPlayerQuestionsLeft = useSetAtom(multiPlayerQuestionsLeftAtom)
    const setSinglePlayerAttemptedAnswer = useSetAtom(singlePlayerAnswerAtom)
    const setSinglePlayerIsAnswerCorrect = useSetAtom(singlePlayerIsAnswerCorrectAtom)
    const setSinglePlayerToast = useSetAtom(singlePlayerToastAtom)
    const setSinglePlayerQuestion = useSetAtom(singlePlayerQuestionAtom)
    const setSinglePlayerChatMessages = useSetAtom(singlePlayerChatMessagesAtom)
    const setSinglePlayerQuestionsLeft = useSetAtom(singlePlayerQuestionsLeftAtom)
    const [noCoinsInterstitialShown, setNoCointsInterstitalShown] = useAtom(noCoinsInterstitialShownAtom)
    const navigate = useNavigate()
    const navigateToDiagnosisChallenge = (diagnosisChallenge: DiagnosisChallengeModeratorResponse) => {
        if (diagnosisChallenge.isMultiPlayer) {
            setMultiPlayerToast(null)
            setMultiPlayerQuestionsLeft(5)
        } else {
            setSinglePlayerAttemptedAnswer("")
            setSinglePlayerIsAnswerCorrect(null)
            setSinglePlayerToast(null)
            setSinglePlayerQuestion("")
            setSinglePlayerChatMessages([])
            setSinglePlayerQuestionsLeft(5)
        }
        navigate(`/diagnosis-challenge/${diagnosisChallenge.id}`, {
            state: {
                diagnosisChallenge: diagnosisChallenge,
                medicalBranches: medicalBranchesValues
                    .filter(medicalBranch => medicalBranch.isSelected)
                    .map(medicalBranch => medicalBranch.title),
                difficulty: difficultiesValues
                    .filter(difficulty => difficulty.isSelected)
                    .map(difficulty => difficulty.title)[0],
                userType: diagnosisChallenge.isMultiPlayer ? UserType.Moderator : UserType.Guesser
            }
        })
    }

    const resetPostCreate = () => {
        resetMedicalBranches(resetMedicalBranchesList)
        resetDifficulties(resetDifficultiesList)
        resetGameTypes(resetGameTypesList)
        setToast(null)
    }

    const resetDifficultiesList = (difficulties: Difficulty[]): Difficulty[] => {
        return difficulties.map((difficulty) => (
            (difficulty.isSelected ?
                {
                    ...difficulty,
                    isSelected: !difficulty.isSelected,
                } : difficulty)
        ))
    }

    const resetGameTypesList = (gameTypes: GameType[]): GameType[] => {
        return gameTypes.map((gameType) => (
            (gameType.isSelected ?
                {
                    ...gameType,
                    isSelected: !gameType.isSelected,
                } : gameType)
        ))
    }

    const resetMedicalBranchesList = (medicalBranches: MedicalBranch[]): MedicalBranch[] => {
        return medicalBranches.map((medicalBranch) => (
            (medicalBranch.isSelected ?
                {
                    ...medicalBranch,
                    isSelected: !medicalBranch.isSelected,
                } : medicalBranch)
        ))
    }

    const medicalBranchesAtoms = splitAtom(medicalBranchesAtom)
    const resetMedicalBranches = useSetAtom(medicalBranchesAtom)
    const medicalBranches = useAtomValue(medicalBranchesAtoms)
    const medicalBranchesValues = useAtomValue(medicalBranchesAtom)
    const shouldShowDifficulty = useAtomValue(shouldShowDifficultyAtom)

    const difficultiesAtoms = splitAtom(difficultiesAtom)
    const resetDifficulties = useSetAtom(difficultiesAtom)
    const difficulties = useAtomValue(difficultiesAtoms)
    const difficultiesValues = useAtomValue(difficultiesAtom)
    const shouldShowGameTypes = useAtomValue(shouldShowGameTypesAtom)

    const gameTypesAtoms = splitAtom(gameTypesAtom)
    const gameTypes = useAtomValue(gameTypesAtoms)
    const resetGameTypes = useSetAtom(gameTypesAtom)

    const selectedGameType = useAtomValue(selectedGameTypeAtom)

    const setToast = useSetAtom(toastAtom)
    const addToast = (message: string) => {
        const newToast: Toast = {message};
        setToast(newToast);
    };

    return (
        <div>
            <h1>Choose which categories you want to include in your diagnosis challenge</h1>
            <div className="medical-branch-container">
                {medicalBranches.map(branch =>
                    <Card key={branch.toString()} atom={branch}/>
                )}
            </div>
            <ExoClickResponsiveAd zoneId={"5338106"} className={"eas6a97888e2"}/>
            <div>
                <ExoClickAd zoneId={"5337378"} className={"eas6a97888e20"} style={{margin: '0 16px'}}/>
            </div>

            {shouldShowDifficulty ? (
                <>
                    <h1>Choose your difficulty</h1>
                    <div className="difficulty-container">
                        {difficulties.map(difficulty =>
                            <Card key={difficulty.toString()} atom={difficulty}
                                  preClick={() => {
                                      resetDifficulties(resetDifficultiesList)
                                  }}/>
                        )}
                    </div>
                </>
            ) : null}
            {shouldShowGameTypes ? (
                <>
                    <h1>Single or multi player?</h1>
                    <div className="game-type-container">
                        {gameTypes.map(gameType =>
                            <Card key={gameType.toString()} atom={gameType} preClick={() => {
                                resetGameTypes(resetGameTypesList)
                            }}/>
                        )}
                    </div>
                    <ExoClickResponsiveAd zoneId={"5340850"} className={"eas6a97888e2"}/>
                    <ExoClickAd zoneId={"5338100"} className={"eas6a97888e37"} style={{margin: '0 16px'}}/>
                </>
            ) : null}
            <GameTypeRulesComponent selectedGameType={selectedGameType}/>
            <ToastContainer atom={toastAtom}/>
            {selectedGameType !== SelectedGameType.None ?
                <>
                    <CenterButton
                        text={"Create Challenge"}
                        loadingText={"Creating Challenge, please wait."}
                        onClick={async () => {
                            if (gamesLeft > 0) {
                                try {
                                    const response = await createDiagnosisChallenge(
                                        medicalBranchesValues
                                            .filter(medicalBranch => medicalBranch.isSelected)
                                            .map(medicalBranch => medicalBranch.title),
                                        difficultiesValues
                                            .filter(difficulty => difficulty.isSelected)
                                            .map(difficulty => difficulty.title)[0],
                                        selectedGameType === SelectedGameType.MultiPlayer
                                    )
                                    setGamesLeft(gamesLeft - 1)
                                    resetPostCreate()
                                    navigateToDiagnosisChallenge(response)
                                } catch (e) {
                                    addToast("Something went wrong, please try again!")
                                }
                            } else {
                                setNoCointsInterstitalShown(true)
                            }
                        }}/>
                    <ExoClickResponsiveAd zoneId={"5338316"} className={"eas6a97888e2"}/>
                </> : null}
            {noCoinsInterstitialShown ? <ExoClickObservable
                zoneId={"5340130"}
                className={"eas6a97888e35"}
                onAdError={() => {
                    addToast("Could not load another game, please try again or comeback tomorrow!")
                    setNoCointsInterstitalShown(false)
                }}
                onAdLoaded={async () => {
                    setNoCointsInterstitalShown(false)
                    try {
                        const response = await createDiagnosisChallenge(
                            medicalBranchesValues
                                .filter(medicalBranch => medicalBranch.isSelected)
                                .map(medicalBranch => medicalBranch.title),
                            difficultiesValues
                                .filter(difficulty => difficulty.isSelected)
                                .map(difficulty => difficulty.title)[0],
                            selectedGameType === SelectedGameType.MultiPlayer
                        )
                        resetPostCreate()
                        navigateToDiagnosisChallenge(response)
                    } catch (e) {
                        addToast("Something went wrong, please try again!")
                    }
                }}/> : null}

            <DisclaimerComponent/>
        </div>
    )
}

const DisclaimerComponent: React.FC = () => {
    return (
        <p className="disclaimer">Disclaimer: These challenges use generative AI to create stories. The symptoms,
            diagnosis steps and treatments are generated based on the information that the AI is trained on, which
            includes medical literature. But, only use this data as a refresher, training and fun challenge. Always
            confirm anything health and medical related using medical literature and licensed medical staff.</p>
    );
}

export default DiagnosisRootComponent
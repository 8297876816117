import React from 'react';
import './FailureCard.css';

interface FailureCardProps {
    message: string;
}

const FailureCard: React.FC<FailureCardProps> = ({ message }) => {
    return (
        <div className="failure-card">
            <p>{message}</p>
        </div>
    );
};

export default FailureCard;
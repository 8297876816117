import React from 'react';
import './SuccessCard.css';

interface SuccessCardProps {
    message: string;
}

const SuccessCard: React.FC<SuccessCardProps> = ({ message }) => {
    return (
        <div className="success-card">
            <p>{message}</p>
        </div>
    );
};

export default SuccessCard;
import React from 'react';
import './CenterButton.css';
import {atom, useAtom} from "jotai/index";
import {DotLoader} from "react-spinners";

interface CenterButtonProps {
    text: string,
    onClick: () => Promise<void>,
    style?: string,
    loadingText?: string,
    enabled?: boolean,
}

const isLoadingAtom = atom(false)
const CenterButton: React.FC<CenterButtonProps> = ({text, onClick, style, loadingText, enabled = true}) => {
    const [isLoading, setIsLoading] = useAtom(isLoadingAtom)
    return (
        <div className="center" onClick={async () => {
            setIsLoading(true)
            await onClick()
            setIsLoading(false)
        }}>
            <button
                className={style || "center-button"}
                type="button"
                disabled={isLoading}
            >
                {isLoading ? <div className="horizontal-align">
                    <DotLoader size={20} color={"#FFFFFF"}/>
                    {loadingText && <span>Creating Diagnosis Challenge, please wait</span>}
                </div> : text
                }
            </button>
        </div>
    );
}

export default CenterButton;
import {atom} from "jotai/index";
import {Toast} from "../../../../common/ui/toast/Toast";
import {ChatMessage} from "../../../../common/presentation/ChatMessage";
import {getRemoteConfigValue} from "../../../../common/RemoteConfigHook";
import {
    DIAGNOSIS_CHALLENGE_MULTI_PLAYER_FREE_QUESTIONS,
} from "../../../../common/data/RemoteConfigConstants";

export const multiPlayerQuestionAtom = atom("");
export const multiPlayerChatMessagesAtom = atom<ChatMessage[]>([]);
export const multiPlayerToast = atom<Toast | null>(null);
export const multiPlayerIsCreatingNewChallengeAtom = atom<boolean>(false)
export const multiPlayerQuestionsLeftAtom = atom<number>(Number(getRemoteConfigValue(DIAGNOSIS_CHALLENGE_MULTI_PLAYER_FREE_QUESTIONS)))

import React, {Fragment} from 'react';
import {SelectedGameType} from "../SelectedGameType";
import RulesOfGameCard from "../../../../common/ui/rules/RulesOfGameCard";
import {
    rulesMultiPlayer,
    rulesMultiPlayerGuesser,
    rulesMultiPlayerModerator,
    rulesSinglePlayer
} from "../DiagnosisChallengeRules";

interface GameTypeRulesProps {
    selectedGameType: SelectedGameType
}

const GameTypeRulesComponent: React.FC<GameTypeRulesProps> = ({selectedGameType}) => {
    switch (selectedGameType) {
        case SelectedGameType.None:
            return null;
        case SelectedGameType.SinglePlayer:
            return <RulesOfGameCard content={
                <Fragment>
                    <h1>Single-player rules of the game are:</h1>
                    <ol>
                        {rulesSinglePlayer.map(rule => <li key={rule}>{rule}</li>)}
                    </ol>
                </Fragment>
            }/>
        case SelectedGameType.MultiPlayer:
            return <RulesOfGameCard content={
                <Fragment>
                    <h1>Multi-player rules of the game are:</h1>
                    <ol>
                        {rulesMultiPlayer.map(rule => <li key={rule}>{rule}</li>)}
                    </ol>
                    <h2>Moderator</h2>
                    <ol>
                        {rulesMultiPlayerModerator.map(rule => <li key={rule}>{rule}</li>)}
                    </ol>
                    <h2>Guesser</h2>
                    <ol>
                        {rulesMultiPlayerGuesser.map(rule => <li key={rule}>{rule}</li>)}
                    </ol>
                </Fragment>
            }/>
    }
}
export default GameTypeRulesComponent;
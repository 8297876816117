import React from 'react';
import './MarkoComponent.css';

export const MarkoComponent: React.FC = () => {

    return (
        <div className="root-card">
            <p className={"p-text"}>Hello, I am Marko Petrovski, a Bachelor of Engineering in Information Science and
                Computer Engineering, majoring in Computer Science and Engineering, Founder of Repeatable and an
                experienced, proactive Senior Android Engineer from North Macedonia. With over 6 years of dedicated
                experience in Android development, I have actively contributed to a number of projects, collaborated in
                company initiatives, and facilitated knowledge-sharing workshops. My professional journey has taken me
                across the globe, working with clients from USA, Japan, Canada, Denmark, England, Macedonia, Germany,
                Switzerland. These dynamic experiences have covered a wide spectrum of domains, including medicine,
                banking, cashback, loyalty programs, TV shows, and driving and parking support.
                <br/>
                <br/>
                My professional responsibilities have been diverse, encompassing a wide range of tasks, including being
                the lead engineer on projects, mentoring other engineers, leading the technical aspect of a project and
                its migrations, conducting project estimations, setting up project architectures, developing scalable
                and reliable software solutions, being part of an analytics growth team, being part of a
                cross-functional feature team (containing Android, Backend, Design, Product and Project), refactoring
                legacy code and migrating to newer technologies and standards, conducting code reviews. I have a track
                record of enhancing existing projects in production, whether through feature development, code
                refactoring, or code review. My role also includes constant exploration of new libraries and design
                patterns to ensure that the projects I'm involved in remain up to date with the ever-evolving tech
                industry.
                <br/>
                <br/>
                I have worked for both an agency and a product company, and this combination allows me to care and truly
                invest myself in the work and project as if it was my own product, which is a mindset that I feel
                everyone that works in a product company should have, and have the attitude of get the job done as fast
                as possible without sacrificing quality, a mindset from the fast paced agency work style.
                My remote background experience also serves as an indicator that regardless of where the team is
                distributed, we can always find a few hours or even minutes to catchup, unblock and continue working
                towards our common goal, and the results have always spoken for themselves.
                <br/>
                <br/>
                Some of the technologies and tools used in these projects and roles include: location services,
                deep-linking, video calling, camera and barcode scanning, fingerprint unlock, BLE communication with a
                separate proprietary devices, Gemalto Mobile Protector SDK, push notifications, analytics, GraphQL,
                WebSockets, Kotlin Multiplatform (KMP), Compose Multiplatform, Jetpack Compose, Koin, Hilt, Room,
                Amplitude, Kotlin Coroutines, Gradle Convention Plugins, RxJava2, Dagger2, AdMob and more. In my daily
                work, I adopt a unidirectional MVVM architecture (MVVM / MVI), leveraging Kotlin Coroutines, Hilt for
                dependency injection, Flow for data streaming, and the latest in UI development with Jetpack Compose,
                using Charles Proxy to mock, and intercept requests and responses and Junit, Mockk, Turbine for testing.
                I believe in the power of adaptability and adjusting what is used based on the project or the client, be
                it opting for Apollo GraphQL in place of a traditional REST API with Retrofit or embracing Koin for
                Kotlin Multiplatform endeavors.
                Some limited SwitUI knowledge can be thrown in here as well when working on a KMP project to be able to
                change a few variables etc.
                <br/>
                <br/>
                I also have some Node.js / Express, MongoDB / Mongoose knowledge from the web backend side, and some
                ReactJs / TypeScript knowledge.
                Some of my non technical skills would include creating UI / UX designs using Material3 guidelines and
                tokens, using Figma and Lottie, creating architecture diagrams.
                <br/>
                <br/>
                As I pursue my next professional chapter, I am excited to bring my extensive experience, expertise, and
                commitment to your team. I look forward to the possibility of joining your organization and contributing
                to your vision.
                Thank you for considering my application, and I am eager to discuss how my skills align with your needs.
                Please feel free to reach out to me. Wishing you all the best and looking forward to the potential of
                working together.
                <br/>
                <br/>
                Sincerely,
                Marko Petrovski
            </p>
        </div>
    );
};
import React, {useEffect, useState} from 'react';
import './MainNavigation.css';
import NavLinks from './NavLinks';
import SideDrawer from './SideDrawer';
import Backdrop from './Backdrop';
import {GamesLeftComponent} from './GamesLeftComponent';
import {JoinDiagnosisChallengeButton} from './JoinDiagnosisChallengeButton';
import {getRemoteConfigValue, initializeRemoteConfig} from '../../RemoteConfigHook';
import {DAILY_FREE_CHALLENGES} from '../../data/RemoteConfigConstants';
import {useAtom} from "jotai";
import {gamesLeftAtom, gamesLeftExpiryDateAtom} from "../../RemoteStorageStore";

const MainNavigation: React.FC = () => {
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const [gamesLeftExpiryDate, setGamesLeftExpiryDate] = useAtom(gamesLeftExpiryDateAtom)
    const [gamesLeft, setGamesLeft] = useAtom(gamesLeftAtom)

    useEffect(() => {
        const fetchConfigValues = async () => {
            await initializeRemoteConfig();
            const dailyChallenges = Number(getRemoteConfigValue(DAILY_FREE_CHALLENGES));
            const now = new Date()
            if (gamesLeftExpiryDate.getTime() < now.getTime()) {
                const midnight = new Date()
                midnight.setHours(24, 0, 0, 0)
                setGamesLeftExpiryDate(midnight)
                setGamesLeft(dailyChallenges)
            }
        };

        fetchConfigValues();
    }, []);

    const openDrawer = () => {
        setDrawerIsOpen(true);
    };

    const closeDrawer = () => {
        setDrawerIsOpen(false);
    };

    return (
        <React.Fragment>
            {drawerIsOpen ? <Backdrop onClick={closeDrawer}/> : null}
            {drawerIsOpen ? (
                <SideDrawer
                    onClick={closeDrawer}
                    show={drawerIsOpen}
                    content={
                        <div className="drawer-content">
                            <nav className="main-navigation-drawer-nav">
                                <NavLinks/>
                            </nav>
                            <div className="join-drawer">
                                <JoinDiagnosisChallengeButton/>
                            </div>
                        </div>
                    }
                />
            ) : null}
            <header className="main-header">
                <button className="main-navigation-menu-btn" onClick={openDrawer}>
                    <span/>
                    <span/>
                    <span/>
                </button>
                <GamesLeftComponent gamesLeft={gamesLeft}/>
                <nav className="main-navigation-header-nav">
                    <NavLinks/>
                </nav>
                <div className="join-nav">
                    <JoinDiagnosisChallengeButton/>
                </div>
            </header>
        </React.Fragment>
    );
};

export default MainNavigation;

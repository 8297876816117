import React, { useEffect, useRef } from 'react';
import "./ExoClickResponsiveAd.css"
import {useAdRefresh} from "./AdRefreshContext";
interface AdProps {
    zoneId: string;
    className?: string;
    style?: React.CSSProperties;
}

const ExoClickResponsiveAd: React.FC<AdProps> = ({ zoneId, className, style }) => {
    const adRef = useRef<HTMLDivElement>(null);
    const { key } = useAdRefresh() // get the key from the context to trigger re-render

    useEffect(() => {
        if (adRef.current) {
            // Clear the ad container
            adRef.current.innerHTML = '';

            // Create the iframe element
            const iframe = document.createElement('iframe');
            iframe.src = `//a.magsrv.com/iframe.php?idzone=${zoneId}&size=auto`;
            iframe.width = "728px";
            iframe.height = "90px";
            iframe.scrolling = 'no';
            iframe.marginWidth = '0';
            iframe.marginHeight = '0';
            iframe.frameBorder = '0';

            // Append the iframe to the ad container
            adRef.current.appendChild(iframe);
        }
    }, [zoneId, key]);

    return (
        <div
            className={"ad-wrapper"}
            ref={adRef}
            style={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                overflow: 'hidden',
                ...style,
            }}
        />
    );
};

export default ExoClickResponsiveAd;

import React, {useEffect, useRef} from 'react';
import './ChatComponent.css';
import {PrimitiveAtom, useAtom} from 'jotai';
import {Input} from "../input/Input";
import CenterButton from "../../../common/ui/button/CenterButton";
import {askDiagnosisChallenge} from "../../../feature/diagnosis/common/data/DiagnosisChallengeApi";
import {ChatMessage} from "../../presentation/ChatMessage";
import {
    singlePlayerQuestionsLeftAtom
} from "../../../feature/diagnosis/common/store/SinglePlayerDiagnosisChallengeAtomStore";

interface ChatComponentProps {
    story: string,
    questionAtom: PrimitiveAtom<string>,
    chatMessagesAtom: PrimitiveAtom<ChatMessage[]>,
    questionsLeftAtom: PrimitiveAtom<number>,
    illness: string,
    additionalAction?: () => void,
}

export const ChatComponent: React.FC<ChatComponentProps> = ({
                                                                story,
                                                                questionAtom,
                                                                chatMessagesAtom,
                                                                illness,
                                                                additionalAction
                                                            }) => {
    const [question, setQuestion] = useAtom(questionAtom);
    const [chatMessages, setChatMessages] = useAtom(chatMessagesAtom);
    const [questionsLeft, setQuestionsLeft] = useAtom(singlePlayerQuestionsLeftAtom)

    const chatContainerRef = useRef<HTMLDivElement>(null);

    const handleAskQuestion = async () => {
        if (question.trim()) {
            setChatMessages([...chatMessages, {sender: 'user', message: question}]);
            setQuestion("");

            try {
                const answer = await askDiagnosisChallenge(story, question, illness)
                if (additionalAction) {
                    additionalAction()
                }
                setChatMessages(prevMessages => [...prevMessages, {sender: 'api', message: answer}]);
                setQuestionsLeft(questionsLeft - 1)
            } catch (error) {
                setChatMessages(prevMessages => [...prevMessages, {
                    sender: 'api',
                    message: 'Something went wrong, please try again.'
                }]);
            }
        }
    };

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatMessages]);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleAskQuestion()
        }
    };

    return (
        <div className="chat-component">
            <p className="questions-left">Questions Left: {questionsLeft}</p>
            <div className="chat-container" ref={chatContainerRef}>
                {chatMessages.map((msg, index) => (
                    <div key={index} className={`chat-message ${msg.sender}`}>
                        {msg.message}
                    </div>
                ))}
            </div>
            <div className="chat-center">
                <Input
                    style={"input-chat"}
                    atom={questionAtom}
                    placeholder={"Enter your question."}
                    onKeyDown={handleKeyPress}
                />
                <CenterButton
                    style={"center-button-chat"}
                    text={"Ask"}
                    onClick={handleAskQuestion}
                    enabled={questionsLeft > 0}
                />
            </div>
        </div>
    );
};

export default ChatComponent;
import React from 'react';

import './Backdrop.css';

interface BackdropPreps {
    onClick: () => void;
}
const Backdrop: React.FC<BackdropPreps> = ({onClick}) => {
    return (<div className="backdrop" onClick={onClick}></div>);
}

export default Backdrop;
import React from 'react';
import {CSSTransition} from 'react-transition-group';
import './SideDrawer.css';

interface SideDrawerProps {
    content: React.ReactNode,
    onClick: () => void,
    show: boolean,
}

const SideDrawer: React.FC<SideDrawerProps> = ({content, show, onClick}) => {
    return (
        <CSSTransition in={show} timeout={200} classNames="slide-in-left" mountOnEnter unmountOnExit>
            <aside className="side-drawer" onClick={onClick}>
                {content}
            </aside>
        </CSSTransition>
    );
}

export default SideDrawer;
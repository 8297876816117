export class CreateDiagnosisChallengeRequest {

    medicalBranches: Array<string>;
    difficulty: string;
    isMultiPlayer: boolean;
    exclude?: Array<string>;

    constructor(medicalBranches: Array<string>, difficulty: string, isMultiPlayer: boolean, exclude?: Array<string>) {
        this.medicalBranches = medicalBranches
        this.difficulty = difficulty
        this.isMultiPlayer = isMultiPlayer
        this.exclude = exclude
    }
}
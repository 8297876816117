import {MedicalBranch} from "./model/MedicalBranch";

export class MedicalBranchRepository {
    medicalBranches = [
        new MedicalBranch('All', './all-mb.svg', false,),
        new MedicalBranch('Random', './random-mb.svg', false,),
        new MedicalBranch('Anesthesiology', './anesthesiology-mb.svg', false,),
        new MedicalBranch('Cardiology', './cardiology-mb.svg', false,),
        new MedicalBranch('Critical care medicine', './critical-care-mb.svg', false,),
        new MedicalBranch('Dentistry', './dentistry-mb.svg', false,),
        new MedicalBranch('Dermatology', './dermatology-mb.svg', false,),
        new MedicalBranch('Emergency medicine', './emergency-medicine-mb.svg', false,),
        new MedicalBranch('Endocrinology', './endocrinology-mb.svg', false,),
        new MedicalBranch('Epidemiology', './epidemiology-mb.svg', false,),
        new MedicalBranch('First Aid', './first-aid-mb.svg', false,),
        new MedicalBranch('Gastroenterology', './gastroenterology-mb.svg', false,),
        new MedicalBranch('General practice', './general-practice-mb.svg', false,),
        new MedicalBranch('Geriatrics', './geriatrics-mb.svg', false,),
        new MedicalBranch('Gynaecology', './gynaecology-mb.svg', false,),
        new MedicalBranch('Hematology', './hematology-mb.svg', false,),
        new MedicalBranch('Hepatology', './hepatology-mb.svg', false,),
        new MedicalBranch('Infectious disease', './infectious-disease-mb.svg', false,),
        new MedicalBranch('Internal medicine', './internal-medicine-mb.svg', false,),
        new MedicalBranch('Neurology', './neurology-mb.svg', false,),
        new MedicalBranch('Nephrology', './nephrology-mb.svg', false,),
        new MedicalBranch('Obstetrics', './obstetrics-mb.svg', false,),
        new MedicalBranch('Oncology', './oncology-mb.svg', false,),
        new MedicalBranch('Ophthalmology', './ophthalmology-mb.svg', false,),
        new MedicalBranch('Optometry', './optometry-mb.svg', false,),
        new MedicalBranch('Orthopaedics', './orthopedics-mb.svg', false,),
        new MedicalBranch('Otorhinolaryngology', './otorhinolaryngology-mb.svg', false,),
        new MedicalBranch('Pathology', './pathology-mb.svg', false,),
        new MedicalBranch('Pediatrics', './pediatrics-mb.svg', false,),
        new MedicalBranch('Preventive medicine', './preventive-medicine-mb.svg', false,),
        new MedicalBranch('Psychiatry', './psychiatry-mb.svg', false,),
        new MedicalBranch('Pulmonology', './pulmology-mb.svg', false,),
        new MedicalBranch('Radiology', './radiology-mb.svg', false,),
        new MedicalBranch('Sports medicine', './sports-medicine-mb.svg', false,),
        new MedicalBranch('Rheumatology', './rheumatology-mb.svg', false,),
        new MedicalBranch('Surgery', './surgery-mb.svg', false,),
        new MedicalBranch('Urology', './urology-mb.svg', false,),
    ]
}
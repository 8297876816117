import {NavLink} from "react-router-dom";
import React from "react";
import './JoinDiagnosisChallengeButton.css'

export const JoinDiagnosisChallengeButton: React.FC = () => {
    return (
        <div className="join-challenge-component">
            <button>
                <NavLink to="/diagnosis-challenge/join">Join Diagnosis Challenge</NavLink>
            </button>

        </div>
    )
}
export class DiagnosisChallengeModeratorResponse {

    id: string;
    illness: string;
    symptoms: Array<string>;
    diagnosisSteps: Array<string>;
    story: string;
    treatment: string;
    isMultiPlayer: boolean;

    constructor(id: string,
                illness: string,
                symptoms: Array<string>,
                diagnosisSteps: Array<string>,
                story: string,
                treatment: string,
                isMultiPlayer: boolean) {
        this.id = id
        this.illness = illness
        this.symptoms = symptoms
        this.diagnosisSteps = diagnosisSteps
        this.story = story
        this.treatment = treatment
        this.isMultiPlayer = isMultiPlayer
    }
}
import React from 'react';
import {Toast} from './Toast';
import './ToastComponent.css';

interface ToastProps {
    toast: Toast,
    onDismiss:() => void,
}

const ToastComponent: React.FC<ToastProps> = ({toast, onDismiss}) => {
    return (
        <div className="toast">
            <div className="message">{toast.message}</div>
            <div className="dismiss" onClick={onDismiss}>X</div>
        </div>
    );
};

export default ToastComponent;

import {useAtom} from "jotai";
import {PrimitiveAtom} from "jotai/index";
import React from "react";
import './Input.css';

interface InputProps {
    atom: PrimitiveAtom<string>,
    maxLength?: number,
    placeholder: string,
    style?: string,
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
}

export const Input: React.FC<InputProps> = ({atom, maxLength, placeholder, style, onKeyDown}) => {
    const [text, setText] = useAtom(atom)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setText(e.target.value)
    return (
        <input
            className={style || "lobby-pass-input"}
            value={text}
            onChange={handleChange}
            maxLength={maxLength}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
        />
    )
}
import React, { useEffect, useRef, useState } from 'react';

interface AdProps {
    zoneId: string;
    className: string;
    onAdLoaded?: () => Promise<void>;
    onAdError?: () => void;
}

const ExoClickObservable: React.FC<AdProps> = ({ zoneId, className, onAdLoaded, onAdError }) => {
    const adRef = useRef<HTMLDivElement>(null);
    const [adLoaded, setAdLoaded] = useState(false);

    useEffect(() => {
        if (adRef.current) {
            // Clear the ad container
            adRef.current.innerHTML = '';

            // Create the ad script element
            const adScript = document.createElement('script');
            adScript.src = 'https://a.magsrv.com/ad-provider.js';
            adScript.async = true;
            adScript.type = 'application/javascript';

            // Create the ad provider script element
            const providerScript = document.createElement('script');
            providerScript.type = 'application/javascript';
            providerScript.innerHTML = `(AdProvider = window.AdProvider || []).push({"serve": {}});`;

            // Create the ins element for the ad
            const insElement = document.createElement('ins');
            insElement.className = className;
            insElement.setAttribute('data-zoneid', zoneId);

            // Append the elements to the ad container
            adRef.current.appendChild(adScript);
            adRef.current.appendChild(insElement);
            adRef.current.appendChild(providerScript);

            // MutationObserver to detect ad load
            const observer = new MutationObserver(async (mutationsList) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        // Assuming that ad content being added means the ad has loaded
                        if (mutation.addedNodes.length > 0) {
                            setAdLoaded(true);
                            if (onAdLoaded) {
                                await onAdLoaded();
                            }
                            observer.disconnect();
                        }
                    }
                }
            });

            observer.observe(insElement, { childList: true, subtree: true });

            // Handle ad error (simplistic approach)
            setTimeout(() => {
                if (!adLoaded) {
                    if (onAdError) onAdError();
                }
            }, 5000); // 5 seconds timeout for ad loading

        }
    }, [zoneId, className, onAdLoaded, onAdError, adLoaded]);

    return <div ref={adRef}></div>;
};

export default ExoClickObservable;

import {
    DIAGNOSIS_CHALLENGE_MULTI_PLAYER_FREE_QUESTIONS,
    DIAGNOSIS_CHALLENGE_SINGLE_PLAYER_FREE_QUESTIONS
} from "../../../common/data/RemoteConfigConstants";
import {getRemoteConfigValue} from "../../../common/RemoteConfigHook";

const questionsAmountSinglePlayer = Number(getRemoteConfigValue(DIAGNOSIS_CHALLENGE_SINGLE_PLAYER_FREE_QUESTIONS));
const questionsAmountMultiPlayer = Number(getRemoteConfigValue(DIAGNOSIS_CHALLENGE_MULTI_PLAYER_FREE_QUESTIONS));

export const rulesSinglePlayer = ["Carefully read the given story about the patient and extract the information provided within it.",
    `You can ask ${questionsAmountSinglePlayer} questions related to the story if needed. Select the question option and enter your question in the text field below.`,
    "When you think you know the answer, select the answer option and your answer in the text field below."]

export const rulesMultiPlayer = [
    "By choosing the multi-player option, and creating the game you will be a Moderator.",
    "After you press the Create Challenge button you will receive a six digit passkey and a lobby ID. Other people that want to join this diagnosis challenge will need to: " +
    "\nPress the \"Join Diagnosis Challenge\" button." +
    "\nEnter the lobby ID." +
    "Rules for the Moderator and Guesser role will be shown below."
]

export const rulesMultiPlayerModerator = [
    "Carefully read the given story about the patient.",
    "You can come up with any rules and grading as you wish in your group, or follow our recommended rules.",
    "If the guessers ask a question about the patient and the symptoms, you should reference the question and answer based on the symptoms provided.",
    "If the guessers request a test to be run, you should then enter the test in the text field below and read the answer. EX: \"What does an EKG say about the patient\".",
]

export const rulesMultiPlayerGuesser = [
    "You are allowed to ask as many questions as they want about the patient, the story and their symptoms.",
    `You are allowed to request up to ${questionsAmountMultiPlayer} tests to be run on the patient.`,
    "You are allowed to guess the illness only once."
]
import {SelectableCardField} from "./SelectableCardField";

export class MedicalBranch implements SelectableCardField {

    title: string;
    imgUrl: string;
    isSelected: boolean;

    constructor(title: string, imgUrl: string, isSelected: boolean) {
        this.title = title
        this.imgUrl = imgUrl
        this.isSelected = isSelected
    }
}
import React from 'react';
import './GuesserChallengeComponent.css';
import ExoClickAd from "../../../common/ui/ad/ExoClickAd";
import ExoClickResponsiveAd from "../../../common/ui/ad/ExoClickResponsiveAd";

interface GuesserChallengeProps {
    story: string,
}

export const GuesserChallengeComponent: React.FC<GuesserChallengeProps> = ({story}) => {
    return (
        <div className="guesser-root-card">
            <h2>The patients story</h2>
            <p className="guesser-story">{story}</p>
            <ExoClickAd
                zoneId={"5340226"}
                className={"eas6a97888e37"}
                style={{margin: '0 16px'}}/>
            <ExoClickResponsiveAd zoneId={"5340256"}
                                  className={"eas6a97888e2"} />
        </div>
    );
};
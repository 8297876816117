import React from 'react';
import './Card.css';
import {MedicalBranch} from "../../domain/model/MedicalBranch";
import {PrimitiveAtom, useAtom} from "jotai";
import {SelectableCardField} from "../../domain/model/SelectableCardField";

interface CardProps {
    atom: PrimitiveAtom<SelectableCardField>,
    preClick?: () => void,
}

const Card: React.FC<CardProps> = ({atom, preClick}) => {
    const [item, setItem] = useAtom(atom)

    const toggleSelectionState = () =>
        setItem((props: MedicalBranch) => ({...props, isSelected: !props.isSelected}))
    return (
        <div className={item.isSelected ? "card-active" : "card"} onClick={() => {
            if (preClick) {
                preClick()
            }
            toggleSelectionState()
        }
        }>
            <img src={item.imgUrl} alt={item.title} width={110} height={80}/>
            <h4>{item.title}</h4>
        </div>
    );
}

export default Card;
import {MedicalBranchRepository} from "../../../common/domain/MedicalBranchRepository";
import {atom} from "jotai/index";
import {DifficultyRepository} from "../../../common/domain/DifficultyRepository";
import {GameTypeRepository} from "../../../common/domain/GameTypeRepository";
import {SelectedGameType} from "./SelectedGameType";

export const medicalBranchRepository = new MedicalBranchRepository()
export const difficultiesRepository = new DifficultyRepository()
export const gameTypesRepository = new GameTypeRepository()
export const medicalBranchesAtom = atom(medicalBranchRepository.medicalBranches)
export const shouldShowDifficultyAtom = atom((get) => {
    const medicalBranches = get(medicalBranchesAtom)
    return medicalBranches.filter(medicalBranch => medicalBranch.isSelected).length > 0
})

export const difficultiesAtom = atom(difficultiesRepository.difficulties)
export const shouldShowGameTypesAtom = atom((get) => {
    const difficulties = get(difficultiesAtom)
    const medicalBranches = get(medicalBranchesAtom)
    return difficulties.filter(difficulty => difficulty.isSelected).length > 0 &&
        medicalBranches.filter(medicalBranch => medicalBranch.isSelected).length > 0
})

export const gameTypesAtom = atom(gameTypesRepository.gameTypes)

export const selectedGameTypeAtom = atom(get => {
    const gameTypes = get(gameTypesAtom)
    const medicalBranches = get(medicalBranchesAtom)

    const selectedGameTypes = gameTypes.filter(gameType => gameType.isSelected)
    if (selectedGameTypes.length === 0 || medicalBranches.filter(medicalBranch => medicalBranch.isSelected).length === 0) {
        return SelectedGameType.None
    } else if (selectedGameTypes[0].title === 'SinglePlayer') {
        return SelectedGameType.SinglePlayer
    } else {
        return SelectedGameType.MultiPlayer
    }
})

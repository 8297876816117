import React, {Fragment} from 'react';
import './ModeratorChallengeComponent.css';
import {DiagnosisChallengeModeratorResponse} from "../common/data/model/response/DiagnosisChallengeModeratorResponse";
import ChatComponent from "../../../common/ui/chat/ChatComponent";
import {useAtom, useSetAtom} from "jotai/index";
import CenterButton from "../../../common/ui/button/CenterButton";
import {createDiagnosisChallenge} from "../common/data/DiagnosisChallengeApi";
import {useNavigate} from "react-router-dom";
import {Toast} from "../../../common/ui/toast/Toast";
import {LoadingComponent} from "../common/ui/LoadingComponent";
import {
    multiPlayerChatMessagesAtom,
    multiPlayerIsCreatingNewChallengeAtom,
    multiPlayerQuestionAtom,
    multiPlayerQuestionsLeftAtom,
    multiPlayerToast
} from "../common/store/MultiPlayerDiagnosisChallengeAtomStore";
import {gamesLeftAtom} from "../../../common/RemoteStorageStore";
import ExoClickAd from "../../../common/ui/ad/ExoClickAd";
import ExoClickResponsiveAd from "../../../common/ui/ad/ExoClickResponsiveAd";
import {useAdRefresh} from "../../../common/ui/ad/AdRefreshContext";


interface ModeratorChallengeProps {
    challenge: DiagnosisChallengeModeratorResponse,
    medicalBranches: Array<string>,
    difficulty: string,
}

export const ModeratorChallengeComponent: React.FC<ModeratorChallengeProps> = ({
                                                                                   challenge,
                                                                                   medicalBranches,
                                                                                   difficulty,
                                                                               }) => {
    const [gamesLeft, setGamesLeft] = useAtom(gamesLeftAtom)
    const [isCreatingNewChallenge, setIsCreatingNewChallenge] = useAtom(multiPlayerIsCreatingNewChallengeAtom)
    const setToast = useSetAtom(multiPlayerToast)
    const {refreshAd} = useAdRefresh();
    const navigate = useNavigate()

    const addToast = (message: string) => {
        const newToast: Toast = {message};
        setToast(newToast);
    };

    const navigateToDiagnosisChallenge = (diagnosisChallenge: DiagnosisChallengeModeratorResponse) => {
        navigate(`/diagnosis-challenge/${diagnosisChallenge.id}`, {
            replace: true,
            state: {
                diagnosisChallenge: diagnosisChallenge,
                medicalBranches: medicalBranches,
                difficulty: difficulty,
            }
        })
    }

    const navigateToHome = () => {
        navigate(`/`, {replace: true,})
    }

    const handleGenerateChallenge = async () => {
        if (gamesLeft > 0) {
            try {
                setIsCreatingNewChallenge(true)
                refreshAd()
                resetState()
                const diagnosisChallenge = await createDiagnosisChallenge(
                    medicalBranches,
                    difficulty,
                    true,
                    [challenge.illness]
                )
                setGamesLeft(gamesLeft - 1)
                setIsCreatingNewChallenge(false)
                navigateToDiagnosisChallenge(diagnosisChallenge)
            } catch (error) {
                addToast("Something went wrong, please try again!")
            }
        } else {
            //ad
        }
    }

    const handleFinishDiagnosisChallenge = async () => {
        resetState()
        navigateToHome()
    }

    const resetState = () => {
        setToast(null)
    }

    return (
        <div className="root-card-moderator">
            {!isCreatingNewChallenge ? <Fragment>
                <h3 className="moderator-header">Lobby ID: {challenge.id}</h3>
                <h3 className="moderator-header">Illness: {challenge.illness}</h3>
                <h3 className="moderator-header">Symptoms:</h3>
                <ol>
                    {challenge.symptoms.map(symptom => <li key={symptom}>{symptom}</li>)}
                </ol>
                <h3 className="moderator-header">Diagnosis steps:</h3>
                <ol>
                    {challenge.diagnosisSteps.map(diagnosisStep => <li key={diagnosisStep}>{diagnosisStep}</li>)}
                </ol>
                <h3 className="moderator-header">Treatment</h3>
                <p className={"diagnosis-challenge-story"}>{challenge.treatment}</p>
                <h3 className="moderator-header">Story</h3>
                <p className="diagnosis-challenge-story">{challenge.story}</p>
                <ExoClickAd
                    zoneId={"5340228"}
                    className={"eas6a97888e37"}
                    style={{margin: '0 16px'}}/>
                <ExoClickResponsiveAd zoneId={"5340260"}
                                      className={"eas6a97888e2"} />
                <h2>Ask questions related to the patient and the story:</h2>
                <ChatComponent
                    story={challenge.story}
                    questionAtom={multiPlayerQuestionAtom}
                    questionsLeftAtom={multiPlayerQuestionsLeftAtom}
                    chatMessagesAtom={multiPlayerChatMessagesAtom}
                    illness={challenge.illness}
                />
                <div className="moderator-bottom-cta">
                    <CenterButton
                        text={"Attempt another challenge?"}
                        onClick={handleGenerateChallenge}/>
                    <CenterButton
                        text={"Finish Diagnosis Challenge"}
                        onClick={handleFinishDiagnosisChallenge}/>
                </div>
                <ExoClickResponsiveAd zoneId={"5340262"}
                                      className={"eas6a97888e2"} />
            </Fragment> : <LoadingComponent/>}
        </div>
    );
};
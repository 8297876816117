import React, {Fragment} from 'react';
import './SinglePlayerDiagnosisChallengeComponent.css';
import ChatComponent from "../../../common/ui/chat/ChatComponent";
import {Input} from "../../../common/ui/input/Input";
import {useSetAtom} from "jotai/index";
import CenterButton from "../../../common/ui/button/CenterButton";
import {useAtom} from "jotai";
import {answerDiagnosisChallenge, createDiagnosisChallenge} from "../common/data/DiagnosisChallengeApi";
import {Toast} from "../../../common/ui/toast/Toast";
import ToastContainer from "../../../common/ui/toast/ToastContainer";
import SuccessCard from "../../../common/ui/card/success/SuccessCard";
import FailureCard from "../../../common/ui/card/failure/FailureCard";
import {DiagnosisChallengeModeratorResponse} from "../common/data/model/response/DiagnosisChallengeModeratorResponse";
import {useNavigate} from "react-router-dom";
import {LoadingComponent} from "../common/ui/LoadingComponent";
import {
    singlePlayerAnswerAtom,
    singlePlayerChatMessagesAtom,
    singlePlayerIsAnswerCorrectAtom,
    singlePlayerIsCreatingNewChallengeAtom,
    singlePlayerQuestionAtom,
    singlePlayerQuestionsLeftAtom,
    singlePlayerToastAtom
} from "../common/store/SinglePlayerDiagnosisChallengeAtomStore";
import {DiagnosisChallengeGuesserResponse} from "../common/data/model/response/DiagnosisChallengeGuesserResponse";
import {gamesLeftAtom} from "../../../common/RemoteStorageStore";
import {getRemoteConfigValue} from "../../../common/RemoteConfigHook";
import {DIAGNOSIS_CHALLENGE_SINGLE_PLAYER_FREE_QUESTIONS} from "../../../common/data/RemoteConfigConstants";
import ExoClickAd from "../../../common/ui/ad/ExoClickAd";
import ExoClickResponsiveAd from "../../../common/ui/ad/ExoClickResponsiveAd";
import {useAdRefresh} from "../../../common/ui/ad/AdRefreshContext";

interface SinglePlayerDiagnosisChallengeProps {
    challenge: DiagnosisChallengeModeratorResponse,
    medicalBranches: Array<string>,
    difficulty: string,
}

export const SinglePlayerDiagnosisChallengeComponent: React.FC<SinglePlayerDiagnosisChallengeProps> = (
    {
        challenge,
        medicalBranches,
        difficulty,
    }
) => {
    const [gamesLeft, setGamesLeft] = useAtom(gamesLeftAtom)
    const [attemptedAnswer, setAttemptedAnswer] = useAtom(singlePlayerAnswerAtom)
    const setToast = useSetAtom(singlePlayerToastAtom)
    const [isAnswerCorrect, setIsAnswerCorrect] = useAtom(singlePlayerIsAnswerCorrectAtom)
    const setQuestionsLeft = useSetAtom(singlePlayerQuestionsLeftAtom)
    const setQuestion = useSetAtom(singlePlayerQuestionAtom)
    const setChatMessages = useSetAtom(singlePlayerChatMessagesAtom)
    const [isCreatingNewChallenge, setIsCreatingNewChallenge] = useAtom(singlePlayerIsCreatingNewChallengeAtom)
    const {refreshAd} = useAdRefresh();

    const addToast = (message: string) => {
        const newToast: Toast = {message};
        setToast(newToast);
    };

    const navigate = useNavigate()
    const navigateToDiagnosisChallenge = (diagnosisChallenge: DiagnosisChallengeModeratorResponse) => {
        navigate(`/diagnosis-challenge/${diagnosisChallenge.id}`, {
            replace: true,
            state: {
                diagnosisChallenge: diagnosisChallenge,
                medicalBranches: medicalBranches,
                difficulty: difficulty,
            }
        })
    }

    const navigateToHome = () => {
        navigate(`/`, {replace: true,})
    }

    const handleAttemptAnswer = async () => {
        if (attemptedAnswer.trim()) {
            refreshAd()
            setAttemptedAnswer(attemptedAnswer);

            try {
                const isCorrect = await answerDiagnosisChallenge(attemptedAnswer, challenge.illness)
                setIsAnswerCorrect(isCorrect)
            } catch (error) {
                addToast("Something went wrong, please try again!")
            }
        }
    };

    const handleGenerateChallenge = async () => {
        if (gamesLeft > 0) {
            try {
                refreshAd()
                setIsCreatingNewChallenge(true)
                resetState()
                const diagnosisChallenge = await createDiagnosisChallenge(
                    medicalBranches,
                    difficulty,
                    false,
                    [challenge.illness]
                )
                setGamesLeft(gamesLeft - 1)
                setIsCreatingNewChallenge(false)
                navigateToDiagnosisChallenge(diagnosisChallenge)
            } catch (error) {
                addToast("Something went wrong, please try again!")
            }
        } else {
            //watch ad
        }
    }

    const handleFinishDiagnosisChallenge = async () => {
        resetState()
        navigateToHome()
    }

    const resetState = () => {
        setAttemptedAnswer("")
        setIsAnswerCorrect(null)
        setToast(null)
        setQuestion("")
        setChatMessages([])
        setQuestionsLeft(Number(getRemoteConfigValue(DIAGNOSIS_CHALLENGE_SINGLE_PLAYER_FREE_QUESTIONS)))
    }


    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleAttemptAnswer()
        }
    };

    return (
        <div className="root-card-guesser">
            {!isCreatingNewChallenge ? <Fragment>
                <h2>The patient's story</h2>
                <p className="diagnosis-challenge-story">{challenge.story}</p>
                <h2>Ask questions related to the patient and the story:</h2>
                <p className="diagnosis-challenge-story">You
                    have {Number(getRemoteConfigValue(DIAGNOSIS_CHALLENGE_SINGLE_PLAYER_FREE_QUESTIONS))} questions to
                    ask per challenge, then you must watch
                    an ad for additional questions.</p>
                <ChatComponent
                    questionAtom={singlePlayerQuestionAtom}
                    questionsLeftAtom={singlePlayerQuestionsLeftAtom}
                    chatMessagesAtom={singlePlayerChatMessagesAtom}
                    story={challenge.story}
                    illness={challenge.illness}
                    additionalAction={refreshAd}/>
                <ExoClickAd
                    zoneId={"5340220"}
                    className={"eas6a97888e37"}
                    style={{margin: '0 16px'}}/>
                <ExoClickResponsiveAd zoneId={"5340248"}
                                      className={"eas6a97888e2"}/>
                <h2>Do you think you know the answer?</h2>
                <p className="diagnosis-challenge-story">Submit your answer below, though remember you have only 1
                    attempt!</p>
                <div className="answer-center">
                    <Input
                        style={"input-answer"}
                        atom={singlePlayerAnswerAtom}
                        placeholder={"Enter your answer."}
                        onKeyDown={handleKeyPress}
                    />
                    <CenterButton
                        style={"center-button-chat"}
                        text={"Answer"}
                        onClick={handleAttemptAnswer}/>
                </div>
                <ExoClickResponsiveAd zoneId={"5340250"}
                                      className={"eas6a97888e2"}/>
                {
                    isAnswerCorrect !== null && <PostAnswerComponent
                        isAnswerCorrect={isAnswerCorrect}
                        challenge={challenge}
                        onAttemptAnotherChallengeClick={handleGenerateChallenge}
                        onFinishChallengeClick={handleFinishDiagnosisChallenge}
                    />
                }
                <ToastContainer atom={singlePlayerToastAtom}/>
            </Fragment> : <LoadingComponent/>}
        </div>
    );
};

interface PostAnswerComponentProps {
    isAnswerCorrect: boolean,
    challenge: DiagnosisChallengeGuesserResponse,
    onAttemptAnotherChallengeClick: () => Promise<void>,
    onFinishChallengeClick: () => Promise<void>,
}

const PostAnswerComponent: React.FC<PostAnswerComponentProps> = (
    {
        isAnswerCorrect,
        challenge,
        onAttemptAnotherChallengeClick,
        onFinishChallengeClick
    }
) => {
    return (
        <div>
            {isAnswerCorrect ? <SuccessCard message={`You are correct! The answer is ${challenge.illness}`}/> :
                <FailureCard message={`Sadly you are incorrect. The answer is ${challenge.illness}`}/>}
            <h3 className="diagnosis-challenge-answer-headers">Treatment:</h3>
            <p className={"diagnosis-challenge-treatment"}>{challenge.treatment}</p>
            <h3 className="diagnosis-challenge-answer-headers">Symptoms:</h3>
            <ol>
                {challenge.symptoms.map(symptom => <li key={symptom}>{symptom}</li>)}
            </ol>
            <h3 className="diagnosis-challenge-answer-headers">Diagnosis steps:</h3>
            <ol>
                {challenge.diagnosisSteps.map(diagnosisStep => <li key={diagnosisStep}>{diagnosisStep}</li>)}
            </ol>
            <div className="single-player-bottom-cta">
                <CenterButton
                    text={"Attempt another challenge?"}
                    onClick={onAttemptAnotherChallengeClick}/>
                <CenterButton
                    text={"Finish Diagnosis Challenge"}
                    onClick={onFinishChallengeClick}/>
            </div>
        </div>
    )
}
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface AdRefreshContextProps {
    key: number;
    refreshAd: () => void;
}

const AdRefreshContext = createContext<AdRefreshContextProps | undefined>(undefined);

export const useAdRefresh = () => {
    console.log("useAdRefresh")
    const context = useContext(AdRefreshContext);
    if (!context) {
        console.log("useAdRefresh")
        throw new Error('useAdRefresh must be used within an AdRefreshProvider');
    }
    return context;
};

interface AdRefreshProviderProps {
    children: ReactNode;
}

export const AdRefreshProvider: React.FC<AdRefreshProviderProps> = ({ children }) => {
    const [key, setKey] = useState(0);

    const refreshAd = () => {
        setKey(prevKey => prevKey + 1);
    };

    return (
        <AdRefreshContext.Provider value={{ key, refreshAd }}>
            {children}
        </AdRefreshContext.Provider>
    );
};

import React, { useEffect, useRef } from 'react';
import {useAdRefresh} from "./AdRefreshContext";

interface AdProps {
    zoneId: string;
    className: string;
    style?: React.CSSProperties;
}

const ExoClickAd: React.FC<AdProps> = ({ zoneId, className, style }) => {
    const adRef = useRef<HTMLDivElement>(null);
    const { key } = useAdRefresh() // get the key from the context to trigger re-render

    useEffect(() => {
        if (adRef.current) {
            // Clear the ad container
            adRef.current.innerHTML = '';

            // Create the ad script element
            const adScript = document.createElement('script');
            adScript.src = 'https://a.magsrv.com/ad-provider.js';
            adScript.async = true;
            adScript.type = 'application/javascript';

            // Create the ad provider script element
            const providerScript = document.createElement('script');
            providerScript.type = 'application/javascript';
            providerScript.innerHTML = `(AdProvider = window.AdProvider || []).push({"serve": {}});`;

            // Create the ins element for the ad
            const insElement = document.createElement('ins');
            insElement.className = className;
            insElement.setAttribute('data-zoneid', zoneId);

            // Append the elements to the ad container
            adRef.current.appendChild(adScript);
            adRef.current.appendChild(insElement);
            adRef.current.appendChild(providerScript);

            // Event listener for creativeDisplayed
            const eventHandler = (e: Event) => {
                const customEvent = e as CustomEvent;
                console.log("Creative shown!");
                console.log(customEvent.detail);
                console.log(customEvent.detail.elm.href);
            };
            document.addEventListener(`creativeDisplayed-${zoneId}`, eventHandler, false);

            // Cleanup event listener on unmount
            return () => {
                document.removeEventListener(`creativeDisplayed-${zoneId}`, eventHandler, false);
            };
        }
    }, [zoneId, className, key]);

    return (
        <div
            ref={adRef}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...style,
            }}
        />
    );
};

export default ExoClickAd;
